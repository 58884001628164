@import 'reset';
@import 'colors';
@import 'variables';

body {
    background: #fff;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: bold;

    img {
        width: 30%;
    }
}

footer {
    width: 100vw;
    text-align: center;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 14px;
    }

    .download {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 150px;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.queue-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
        display: flex;
        border: 1px solid #333;
        border-radius: 10px;
        overflow: hidden;
        
        .letter-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            font-size: 40px;

            .label {
                
            }
        }

        .label-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 25px;
            padding: 0 10px;

            .icon {
                width: 15%;
            }

            .description {
                font-size: 18px;
            }
        }
    }
}

.sms-dialog {
    p {
        font-weight: 500;
        margin-bottom: 10px;
    }

    input {
        border: 1px solid #ccc;
        padding: 10px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    button {
        border-radius: 10px;
        background: $primaryColor;
        border: none;
        padding: 10px;
        color: white;
        font-weight: bold;
        margin-top: 10px;
    }
}

.tickets-button {
    border-radius: 10px;
    background: $primaryColor;
    border: none;
    padding: 15px;
    color: white;
    font-weight: bold;
    margin-top: 40px;
}

.popupContainer {
    position: fixed;
    z-index: 10; /* Stay on Top*/
    padding-top: 10%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* In case it needs scroll */
    background-color: rgba(0,0,0,0.4);
}

.popupContent {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    position: relative;
    text-align: center;
    z-index: 11;
}

.ticketsImg{
    width: 15%;
    border: 1px solid black;
    margin: 5px;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 5px 0 0;
    font-size:x-large;
}

.closeButton:hover,
.closeButton:focus {
  text-decoration: none;
  cursor: pointer;
}

.ticketsList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ticketsTitle{
    text-align: center;
}

.ticketsButton{
    width: 10%;
    align-self: center;
}

.backButton{
    position: absolute;
    top: 0;
    left: 0;
    margin: 5px 0 0 5px;
    font-size:x-large;
}

.backButton:hover,
.backButton:focus {
  text-decoration: none;
  cursor: pointer;
}

.removeOverflow{
    overflow: hidden!important;
}

// @media only screen and (max-width: 600px) {
//     .letterLabelContainer {
//         font-size: 24px;
//     }

//     .labelContainer {
//         font-size: 18px;
//     }

//     .ticketsButton{
//         width: 50%;
//         align-self: center;
//     }
// }